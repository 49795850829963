import useDebounce from "hooks/useDebounce"
import { useEffect, useState } from "react"

import { useGetDictionaryTerm } from "data/contentData/hooks/config.hooks"

import { useTextSelection } from "./useTextSelection"

/*
 * Takes text selection and decides if it should search
 * @returns
 */
export function useDictionarySelectionSearch() {
    const textContent = useTextSelection()
    const [enabled, setEnabled] = useState(false)
    const debouncedTextContent = useDebounce(textContent, 400)
    const { data: definition } = useGetDictionaryTerm(
        debouncedTextContent?.textContent,
        enabled
    )

    const reset = () =>
        setEnabled(prev => {
            if (prev === false) return prev
            return false
        })

    useEffect(() => {
        const shouldSearch = debouncedTextContent
            ? debouncedTextContent.textContent.split(" ").length < 4
            : false

        setEnabled(prev => {
            if (prev === shouldSearch) return prev
            return shouldSearch
        })
    }, [debouncedTextContent])

    useEffect(() => {
        if (!textContent?.textContent) reset()
    }, [textContent])

    useEffect(() => {
        const resetEnable = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                reset()
            }
        }

        document.addEventListener("keydown", resetEnable)
        return () => {
            document.removeEventListener("keydown", resetEnable)
        }
    }, [])

    if (definition) {
        return {
            definition,
            rect: debouncedTextContent?.clientRect
        }
    }

    return {
        definition: undefined,
        rect: undefined
    }
}
