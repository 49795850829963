import { IPage } from "data/contentData/api/content"
import * as React from "react"
import { useState } from "react"

/**
 * This component needs to exist since we lazy-load content.
 * If there is a #hash we continiously try to scroll the element into view until 5 seconds
 * or the user interacts with the page
 */
export default function useEventualScroll(page: IPage) {
    const [firstPageRender, setFirstPageRender] = useState(true)
    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            setFirstPageRender(false)
        }, 5000)

        const disconnect = () => {
            clearTimeout(timeoutId)
            setFirstPageRender(false)
        }

        window.addEventListener("mousedown", disconnect, { once: true })
        window.addEventListener("keydown", disconnect, { once: true })
        window.addEventListener("touchmove", disconnect, {
            once: true,
            passive: true
        })
        window.addEventListener("wheel", disconnect, {
            once: true,
            passive: true
        })

        if (!window.location.hash || !firstPageRender) {
            return
        }

        // This regex removes everything after ? and replaces # with "" since we now look after ids without the # prefix
        const hash = window.location.hash.replace(/(\?.*)|\#/, "")
        const el = document.getElementById(hash)

        if (!el) {
            return
        }

        el.scrollIntoView(true)

        return () => {
            disconnect()
            window.removeEventListener("mousedown", disconnect)
            window.removeEventListener("keydown", disconnect)
            window.removeEventListener("touchmove", disconnect)
            window.removeEventListener("wheel", disconnect)
        }
    }, [page, firstPageRender, setFirstPageRender])
}
