import { Icon } from "components/Icon";
import { Image } from "components/Image";
import PortableText from "components/PortableText/PortableText";
import { ImportantUpdatesList } from "components/frontpage/ImportantUpdatesContent";
import { LatestArticlesList } from "components/frontpage/LatestArticlesContent";
import { frontpageCustomComponents } from "components/frontpage/frontpageCustomComponents";
import Grid from "@mui/material/Grid";
import { GridSize } from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { IFrontpageCard } from "data/contentData/api/content";
import { CONTENT_TYPE, getContentType } from "lib/get-content-type";
import Button from "@mui/material/Button";
interface IFrontpageCardProps extends IFrontpageCard {
  isChild?: boolean;
}
export function Card(props: IFrontpageCardProps) {
  const {
    types,
    image,
    imageType,
    alignment,
    preTitle,
    title,
    blockContent,
    color,
    columns,
    icon,
    templateGroup,
    take: limit,
    includeNew,
    includeUpdated,
    includeRevised,
    onlyImportant,
    displayDate,
    more,
    isChild = false,
    cards
  } = props;
  let content = <PortableText value={blockContent} customComponents={frontpageCustomComponents(color)} />;
  const cardType = getContentType(types);
  function useButtonVariant() {
    if (color === "primary" || color === "secondary") {
      return "outlinedOnDark";
    } else {
      return "outlined";
    }
  }
  const variant = useButtonVariant();
  if (cardType === CONTENT_TYPE.UPDATES_CARD && includeNew !== undefined && includeRevised !== undefined && includeUpdated !== undefined && limit !== undefined && templateGroup !== undefined && displayDate !== undefined) {
    content = <LatestArticlesList includeNew={includeNew} includeRevised={includeRevised} includeUpdated={includeUpdated} limit={limit} templateGroup={templateGroup} displayDate={displayDate} cardColor={color} />;
  }
  if (cardType === CONTENT_TYPE.ARTICLE_UPDATES_CARD && onlyImportant !== undefined && displayDate !== undefined && limit !== undefined) {
    content = <ImportantUpdatesList onlyImportant={onlyImportant} displayDate={displayDate} take={limit} skip={0} cardColor={color} />;
  }
  const card = <>
            {image ? <StyledImage width={image.width} height={image.height} columncount={columns} ischild={isChild.toString()} imagetype={imageType} alt={title ?? ""} src={image.src} /> : null}

            <StyledContentWrapper>
                <StyledContent alignment={alignment} ischild={isChild}>
                    {preTitle ? <Typography gutterBottom variant="h5" component="h3">
                            {preTitle}
                        </Typography> : null}
                    {title ? <Typography variant="h3" component="h2" alignItems="center" gap={1} display="flex">
                            {icon ? <Icon name={icon} /> : null}
                            {title}
                        </Typography> : null}
                    {content}
                    {cards && !isChild ? <Grid container spacing={4}>
                            {cards.map(child => <Card key={child.contentId} {...child as IFrontpageCard} isChild />)}
                        </Grid> : null}
                    {/* | "default" -> outlined | "primary" -> outlinedOnDark | "secondary" -> outlinedOnDark | "grey" -> outlined | "white" -> outlined */}
                    {more ? <MoreWrapper>
                            <Button href={more.href} variant={variant}>
                                {more.text}
                            </Button>
                        </MoreWrapper> : null}
                </StyledContent>
            </StyledContentWrapper>
        </>;
  return <Grid item md={columns as GridSize} xs={12} data-sentry-element="Grid" data-sentry-component="Card" data-sentry-source-file="Card.tsx">
            {isChild ? <StyledCardChild className="frontpage-card">
                    {card}
                </StyledCardChild> : <StyledCard className="frontpage-card" cardcolor={color.toString()} columncount={columns}>
                    {card}
                </StyledCard>}
        </Grid>;
}
const MoreWrapper = styled("div")(() => ({
  alignItems: "flex-end",
  display: "flex",
  flex: 1,
  justifyContent: "flex-end"
}));
const StyledCardChild = styled("div")(() => ({
  display: "flex",
  flexDirection: "column"
}));
const StyledCard = styled("div")<{
  cardcolor: string;
  columncount: number;
}>(({
  cardcolor,
  columncount,
  theme
}) => {
  const {
    cds,
    bhc,
    common,
    text,
    grey
  } = theme.palette;
  const colorsScheme = {
    white: {
      background: common.white,
      border: cds[900],
      text: text.default
    },
    default: {
      background: common.white,
      border: grey[300],
      text: text.default
    },
    grey: {
      background: grey[100],
      border: grey[300],
      text: null
    },
    primary: {
      background: bhc[800],
      text: text.onDark,
      border: null
    },
    secondary: {
      background: cds[700],
      text: text.onDark,
      border: null
    }
  };
  const colors = colorsScheme[(cardcolor ?? "default") as keyof typeof colorsScheme];
  return {
    color: colors.text ?? text.default,
    backgroundColor: colors.background,
    border: !colors.border ? undefined : `2px solid ${colors.border}`,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      flexDirection: columncount <= 4 ? "column" : "row"
    }
  };
});
const StyledContentWrapper = styled("div")(() => ({
  display: "flex",
  flex: 1
}));
const StyledContent = styled("div")<{
  alignment: string;
  ischild: boolean;
}>(({
  alignment,
  ischild,
  theme
}) => {
  return {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    padding: ischild ? 0 : theme.spacing(4),
    textAlign: alignment === "center" ? "center" : "left"
  };
});
const StyledImage = styled(Image)<{
  columncount: number;
  ischild: string;
  imagetype: string;
}>(({
  columncount,
  ischild,
  imagetype,
  theme
}) => {
  const imageSizeAdaptor = (columnCount: number) => {
    if (columnCount >= 12) {
      return "32.4%";
    } else if (columnCount > 4) {
      return "48.958%";
    }
    return "100%";
  };
  return {
    flex: columncount > 4 ? "1" : undefined,
    width: "100%",
    height: "auto",
    minHeight: ischild === "true" ? "initial" : "150px",
    objectFit: imagetype === "photo" ? "cover" : "none",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      maxWidth: imageSizeAdaptor(columncount),
      objectFit: imagetype === "photo" ? "cover" : "contain",
      margin: imagetype === "photo" ? undefined : theme.spacing(0, 6)
    }
  };
});