import { Icon } from "components/Icon";
import { t } from "locales";
import { useState } from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
export default function CopyHtmlButton() {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyHtml = async () => {
    const article = document.getElementById("htmlArticle");
    if (article) {
      const toCopy = [new ClipboardItem({
        "text/html": new Blob([article.outerHTML], {
          type: "text/html"
        })
      })];
      navigator.clipboard.write(toCopy);
    }
    setIsCopied(true);
  };
  return <Tooltip title={t["new-articles"]["copy-as-html"]["tooltip"]} data-sentry-element="Tooltip" data-sentry-component="CopyHtmlButton" data-sentry-source-file="CopyHtmlButton.tsx">
            <Button size="small" disabled={isCopied} startIcon={isCopied ? <Icon name="check" /> : <Icon name="fileCopy" />} variant="filled" onClick={handleCopyHtml} data-sentry-element="Button" data-sentry-source-file="CopyHtmlButton.tsx">
                {isCopied ? t["new-articles"]["copy-as-html"].true : "Copy"}
            </Button>
        </Tooltip>;
}