"use client";

import React from "react";
import { useGetUserSettings } from "data/contentData/hooks/user.hooks";
import { DictionaryPopoverWithLookup } from "./DictionaryPopoverWithLookup";

/**
 * Looks up selection and display results in a tolltip
 * @returns
 */
export function DictionaryPopover() {
  const {
    data: userSettings
  } = useGetUserSettings();
  if (!userSettings?.markWordsInDictionary) return null;
  return <DictionaryPopoverWithLookup data-sentry-element="DictionaryPopoverWithLookup" data-sentry-component="DictionaryPopover" data-sentry-source-file="DictionaryPopover.tsx" />;
}