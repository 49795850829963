import { config } from "config";
import usePath from "hooks/usePath";
import { t } from "locales";
import { ReactNode } from "react";
import { IAuthor } from "data/contentData/api/content";
interface MetaProps {
  title: string;
  description?: string;
  type?: string;
  image?: {
    src: string;
    alt: string;
  };
  dateModified?: Date;
  isPublic?: boolean;
  datePublished?: Date;
  url?: string;
  authors?: IAuthor[];
  noIndex?: boolean;
  children?: ReactNode;
  metadata?: {
    title: string;
    description: string;
  };
}
const logo = `/${process.env.NEXT_PUBLIC_ID}/logo_white.svg`;
export default function Meta(props: MetaProps) {
  const {
    title,
    description,
    type = "website",
    url,
    dateModified,
    datePublished,
    authors,
    isPublic
  } = props;
  const pathname = usePath();
  const _url = `${config.general.appHost ?? ""}${url ?? pathname}`;
  if (type !== "article") {
    return null;
  }
  return <script dangerouslySetInnerHTML={{
    __html: JSON.stringify(removeFalsy({
      "@context": "http://schema.org",
      "@type": "Article",
      author: authors?.map(author => removeFalsy({
        "@type": "Person",
        affiliation: author.organization,
        jobTitle: author.title,
        name: author.name
      })),
      dateModified,
      datePublished,
      description,
      hasPart: !isPublic && [{
        "@type": "WebPageElement",
        cssSelector: ".paywall",
        isAccessibleForFree: "False"
      }, {
        "@type": "WebPageElement",
        cssSelector: ".summary",
        isAccessibleForFree: "True"
      }],
      headline: title,
      image: logo,
      isAccessibleForFree: !isPublic && "False",
      mainEntityOfPage: {
        "@id": removeTrailingSlashes(_url),
        "@type": "WebPage"
      },
      publisher: {
        "@type": "Organization",
        logo: {
          "@type": "ImageObject",
          url: logo
        },
        name: t["site-name"]
      }
    }))
  }} type="application/ld+json" data-sentry-component="Meta" data-sentry-source-file="Meta.tsx" />;
}
function removeFalsy(obj: Record<string, unknown>) {
  const newObj: Record<string, unknown> = {};
  Object.keys(obj).forEach(prop => {
    if (obj[prop]) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
}

/**
 * remove trailing slashes
 * @param pageUrl page url
 * @returns return page url without trailing slashes
 */
function removeTrailingSlashes(pageUrl: string) {
  if (pageUrl?.endsWith("/")) return pageUrl.substring(0, pageUrl.length - 1);
  return pageUrl;
}