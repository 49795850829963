import { useEffect, useState } from "react"

import { IPage } from "data/contentData/api/content"

import useUser from "../../../hooks/useUser"
import { useGetOrganisation } from "data/customerManager/hooks/organisation.hooks"
import {
    getPaywallType,
    PaywallType
} from "components/article/paywall/getPaywallType"

/**
 * Get paywall type for page and user
 * @param page the page to check hasReadAccess
 * @param user the user to check roles
 * @returns PaywallType
 */
export function useGetPaywallType(page: IPage): PaywallType {
    const [paywallType, setPaywallType] = useState<PaywallType>("none")
    const user = useUser()
    const { authenticated, roles, isLoading } = useGetOrganisation()

    const _authenticated = user.authenticated || authenticated
    useEffect(() => {
        const _roles = user.user?.roles || roles || []
        const _isLoading = user.isLoading || isLoading
        const _paywallType = getPaywallType(
            _isLoading,
            page.types,
            page.hasReadAccess || [],
            _authenticated,
            _roles
        )
        setPaywallType(_paywallType)
    }, [
        _authenticated,
        isLoading,
        page.hasReadAccess,
        page.types,
        roles,
        user.isLoading,
        user.user?.roles
    ])

    return paywallType
}
