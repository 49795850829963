"use client";

import { Icon, IconType } from "components/Icon";
import Link from "components/Link";
import { t } from "locales";
import { ReactElement, ReactNode, memo, useState } from "react";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { ILinkList, ILinkListLink } from "data/contentData/api/content";
interface ToolProps {
  icon?: IconType;
  action?: ReactElement;
  title?: string | ReactElement;
  children: ReactNode;
}
function Tool({
  icon,
  title,
  action,
  children
}: ToolProps) {
  return <StyledToolRoot container data-sentry-element="StyledToolRoot" data-sentry-component="Tool" data-sentry-source-file="Tools.tsx">
            <Grid container item alignItems="center" gap={1} data-sentry-element="Grid" data-sentry-source-file="Tools.tsx">
                {icon ? <Icon name={icon} /> : null}
                <Typography fontWeight={700} component="span" variant="body2" data-sentry-element="Typography" data-sentry-source-file="Tools.tsx">
                    {title ?? t.tools.loading.title}
                </Typography>
            </Grid>
            <Grid item xs={12} my={1} data-sentry-element="Grid" data-sentry-source-file="Tools.tsx">
                {children}
            </Grid>
            {action ? <Grid container item justifyContent="flex-end">
                    {action}
                </Grid> : null}
        </StyledToolRoot>;
}
const StyledToolRoot = styled(Grid)(({
  theme
}) => {
  const {
    spacing,
    palette,
    breakpoints
  } = theme;
  return {
    backgroundColor: palette.background.paper,
    boxSizing: "border-box",
    marginTop: -1,
    padding: spacing(2),
    [breakpoints.up("md")]: {
      border: `1px solid ${palette.divider}`
    }
  };
});

/**
 * If the list is longer than `when` items,
 * split it into two at `first`
 */
function splitList(params: {
  links: ILinkListLink[];
  first: number;
  when: number;
}): [ILinkListLink[], ILinkListLink[] | null] {
  const {
    links,
    first,
    when
  } = params;
  if (links.length <= when) {
    return [links, null];
  }
  return [[...links].slice(0, first), [...links].slice(first)];
}
function ToolList(props: ILinkList) {
  const {
    name,
    icon,
    links,
    more
  } = props;
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(e => !e);
  const [first, rest] = splitList({
    links,
    first: 5,
    when: 7
  });
  const action = <>
            {rest && <Button onClick={toggleExpanded} variant="outlined">
                    {expanded ? t["tools"]["show-less"] : t["tools"]["show-more"]}
                </Button>}
            {more && <Link shallow button variant="outlined" href={more.href}>
                    {more.text}
                </Link>}
        </>;
  return <Tool action={action} title={name} icon={icon} data-sentry-element="Tool" data-sentry-component="ToolList" data-sentry-source-file="Tools.tsx">
            <StyledUL data-sentry-element="StyledUL" data-sentry-source-file="Tools.tsx">
                {first.map(item => <li key={item.text} title={item.text}>
                        <StyledListLink noWrap href={item.href} target={item.target}>
                            {item.text}
                        </StyledListLink>
                    </li>)}
                <Collapse in={expanded} data-sentry-element="Collapse" data-sentry-source-file="Tools.tsx">
                    {rest?.map(item => <li key={item.text} title={item.text}>
                            <Link noWrap href={item.href} target={item.target}>
                                {item.text}
                            </Link>
                        </li>)}
                </Collapse>
            </StyledUL>
        </Tool>;
}
const StyledUL = styled("ul")(() => ({
  listStyleType: "none",
  padding: 0,
  margin: 0
}));
const StyledListLink = styled(Link)(({
  theme
}) => {
  const {
    spacing
  } = theme;
  return {
    display: "block",
    padding: spacing(0.25, 0.5),
    margin: spacing(0.25, 0)
  };
});
interface IToolsProps {
  tools?: ILinkList[];
  regionalContentId?: string;
}
function Tools(props: IToolsProps) {
  const {
    tools,
    regionalContentId
  } = props;
  if (!tools?.length) {
    return null;
  }

  // REVIEW: regional-icon should be the same for all handbooks OR come from backend like other tools
  const regionalIconName = process.env.NEXT_PUBLIC_LOCALE === "no" ? "regional" : "room-outlined";
  return <StyledToolsRoot data-sentry-element="StyledToolsRoot" data-sentry-component="Tools" data-sentry-source-file="Tools.tsx">
            {regionalContentId ? <Tool icon={regionalIconName} action={<Link href={`#${regionalContentId}`} variant="body2">
                            {t["tools"]["regional-content"]["action"]}
                        </Link>} title={t["tools"]["regional-content"]["title"]}>
                    <Typography variant="body2" gutterBottom>
                        {t["tools"]["regional-content"]["content"]}
                    </Typography>
                </Tool> : null}
            {tools.map(tool => <ToolList key={tool.name} {...tool} />) ?? null}
        </StyledToolsRoot>;
}
const StyledToolsRoot = styled("div")(({
  theme
}) => {
  const {
    spacing,
    breakpoints,
    constants
  } = theme;
  return {
    maxWidth: constants.width.rightAside,
    "&::-webkit-scrollbar": {
      width: "4px"
    },
    "&::-webkit-scrollbar-track": {
      marginTop: "2px",
      backgroundColor: theme.palette.grey[200]
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: grey[500]
    },
    overflowY: "scroll",
    height: `calc(100vh - ${constants.height.header + constants.height.breadcrumbs}px)`,
    [breakpoints.up("md")]: {
      marginBottom: spacing(4)
    }
  };
});
export default memo(Tools);
export function ToolsSkeleton() {
  return <div data-sentry-component="ToolsSkeleton" data-sentry-source-file="Tools.tsx">
            <Tool title={<Skeleton height={41} width={174} />} data-sentry-element="Tool" data-sentry-source-file="Tools.tsx">
                <Skeleton height={27} width={124} data-sentry-element="Skeleton" data-sentry-source-file="Tools.tsx" />
                <Skeleton height={27} width={148} data-sentry-element="Skeleton" data-sentry-source-file="Tools.tsx" />
            </Tool>
            <Tool title={<Skeleton height={41} width={220} />} data-sentry-element="Tool" data-sentry-source-file="Tools.tsx">
                <Skeleton height={27} width={124} data-sentry-element="Skeleton" data-sentry-source-file="Tools.tsx" />
                <Skeleton height={27} width={161} data-sentry-element="Skeleton" data-sentry-source-file="Tools.tsx" />
                <Skeleton height={27} width={148} data-sentry-element="Skeleton" data-sentry-source-file="Tools.tsx" />
            </Tool>
            <Tool title={<Skeleton height={41} width={240} />} data-sentry-element="Tool" data-sentry-source-file="Tools.tsx">
                <Skeleton height={27} width={124} data-sentry-element="Skeleton" data-sentry-source-file="Tools.tsx" />
                <Skeleton height={27} width={161} data-sentry-element="Skeleton" data-sentry-source-file="Tools.tsx" />
                <Skeleton height={27} width={124} data-sentry-element="Skeleton" data-sentry-source-file="Tools.tsx" />
                <Skeleton height={27} width={192} data-sentry-element="Skeleton" data-sentry-source-file="Tools.tsx" />
                <Skeleton height={27} width={148} data-sentry-element="Skeleton" data-sentry-source-file="Tools.tsx" />
                <StyledDiv data-sentry-element="StyledDiv" data-sentry-source-file="Tools.tsx">
                    <Skeleton height={48} width={80} data-sentry-element="Skeleton" data-sentry-source-file="Tools.tsx" />
                </StyledDiv>
            </Tool>
        </div>;
}
const StyledDiv = styled("div")(() => ({
  display: "flex",
  justifyContent: "flex-end"
}));