import Accordion from "components/UI/Accordion";
import { t } from "locales";
import React from "react";
import Typography from "@mui/material/Typography";
import { IAuthor, IPage } from "data/contentData/api/content";
import theme from "lib/theme";
interface ArticleAuthorsSectionProps {
  id: string;
  page: IPage;
}
export function AuthorsSection({
  id,
  page
}: ArticleAuthorsSectionProps) {
  if (!page.authors) return null;
  const current = page.authors.filter(author => author.current);
  const former = page.authors.filter(author => !author.current);
  const title = t["article"]["authors-title"];
  return <Accordion id={id} level={"h3"} header={title} collapsed={true} summaryBackground={theme.palette.grey[100]} summaryHoverBackground={theme.palette.grey[300]} data-sentry-element="Accordion" data-sentry-component="AuthorsSection" data-sentry-source-file="AuthorsSection.tsx">
            <ul>
                {current.map(author => <Author key={author.name} {...author} />)}
            </ul>

            {former.length ? <>
                    <Typography variant="h4">
                        {t["article"]["former-authors"]}
                    </Typography>
                    <ul>
                        {former.map(author => author.name ? <Author key={author.name} {...author} /> : null)}
                    </ul>
                </> : null}

            {page.includeAuthorText && <InternationalCooperation />}
        </Accordion>;
}
export function Author(props: IAuthor) {
  const {
    name,
    title,
    organization
  } = props;
  return <li data-sentry-component="Author" data-sentry-source-file="AuthorsSection.tsx">
            <span>{name}</span>
            {title ? <Typography component="span" variant="body2">
                    , {title}
                </Typography> : null}
            {organization ? <Typography component="span" variant="body2">
                    , {organization}
                </Typography> : null}
        </li>;
}
function InternationalCooperation() {
  return <>
            <Typography variant="h4" data-sentry-element="Typography" data-sentry-source-file="AuthorsSection.tsx">
                {t["article"]["international-cooperation-title"]}
            </Typography>
            <ul>
                <li>{t["article"]["international-cooperation-content"]}</li>
            </ul>
        </>;
}