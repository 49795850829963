import { t } from "locales";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { IAuthor } from "data/contentData/api/content";
import Link from "components/Link";
interface IProps {
  authors?: IAuthor[];
  authorsContentId: string;
}
export function Authors({
  authors,
  authorsContentId
}: IProps) {
  const _authors = authors?.filter(p => p.current) ?? [];
  if (!_authors.length) {
    return null;
  }
  const author = _authors.map(({
    name,
    title,
    organization
  }) => {
    return {
      name,
      fullText: [name, title, organization].filter(Boolean).join(", ")
    };
  })[0];
  return <Grid item xs={12} data-sentry-element="Grid" data-sentry-component="Authors" data-sentry-source-file="Authors.tsx">
            <Typography variant="caption" mr={1} data-sentry-element="Typography" data-sentry-source-file="Authors.tsx">
                {t["last-approved-by"]}
                {":"}
            </Typography>

            <Tooltip title={author.fullText} data-sentry-element="Tooltip" data-sentry-source-file="Authors.tsx">
                <Link href={`#${authorsContentId}`} rel="author" data-sentry-element="Link" data-sentry-source-file="Authors.tsx">
                    <Typography component="span" variant="body2" data-sentry-element="Typography" data-sentry-source-file="Authors.tsx">
                        {author.name}
                    </Typography>
                </Link>
            </Tooltip>
        </Grid>;
}