import PortableText from "components/PortableText/PortableText";
import Accordion from "components/UI/Accordion";
import { styled, useTheme } from "@mui/material/styles";
import RegionalContent from "components/article/RegionalContent";
import SummaryContent from "components/article/SummaryContent";
import useUser from "hooks/useUser";
import { ISection } from "data/contentData/api/content";
import { noPrintSectionTypes } from "lib/no-print-section-types";
export function ProfessionalPageSection({
  section,
  isPreview = false
}: {
  section: ISection;
  isPreview?: boolean;
}) {
  const {
    authenticated
  } = useUser();
  switch (section.type) {
    case "summary":
      {
        return <SummaryContent key={section.url} id={section.url} content={section.blockContent} />;
      }
    case "root":
      {
        return <PortableText key={section.url ?? "root"} value={section.blockContent} wrapImgWithGalleryLink />;
      }
    case "regional":
      if (!authenticated) return null;
      return <RegionalContent key={section.url} section={section} />;
    default:
      const _section = {
        ...section,
        collapsed: isPreview ? false : section.collapsed
      };
      return <Section key={_section.name} section={_section} noPrint={noPrintSectionTypes.includes(_section.type)} />;
  }
}
interface IProps {
  section: ISection;
  noPrint?: boolean;
}
function Section({
  section,
  noPrint
}: IProps) {
  const {
    blockContent,
    url,
    heading,
    sections,
    collapsed
  } = section;
  const theme = useTheme();
  return <Accordion id={url} level={"h2"} summaryBackground={theme.palette.bhc[800]} summaryColor={theme.palette.text.onDark} borderRadius={"6px"} header={<PortableText value={heading} />} collapsed={collapsed} noPrint={noPrint} data-sentry-element="Accordion" data-sentry-component="Section" data-sentry-source-file="ProfessionalPageSection.tsx">
            {blockContent ? <StyledSubSection>
                    <PortableText value={blockContent} wrapImgWithGalleryLink />
                </StyledSubSection> : null}
            {sections?.map(subSection => {
      const subSectionBlockContent = subSection.blockContent ? <StyledSubSection>
                        <PortableText key={subSection.url} value={subSection.blockContent} wrapImgWithGalleryLink />
                    </StyledSubSection> : null;
      if (subSection.name) {
        return <Accordion key={subSection.name} id={subSection.url} level={"h3"} summaryBackground={theme.palette.grey[100]} summaryHoverBackground={theme.palette.grey[300]} summaryColor={theme.palette.text.default} header={<PortableText value={subSection.heading} />} collapsed={subSection.collapsed}>
                            {subSectionBlockContent}
                        </Accordion>;
      }
      return <StyledSubSection>
                        {subSectionBlockContent}
                    </StyledSubSection>;
    }) ?? null}
        </Accordion>;
}
const StyledSubSection = styled("div")(({
  theme
}) => ({
  color: theme.palette.text.default
}));