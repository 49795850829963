import { t } from "locales";
import React from "react";
import { IAuthor } from "data/contentData/api/content";
interface ArticleAuthorsSectionProps {
  id: string;
  authors: IAuthor[];
}
export function AuthorsSection({
  id,
  authors
}: ArticleAuthorsSectionProps) {
  if (!authors) return null;
  const [currentAuthors, formerAuthors] = authors.reduce((acc, author) => {
    acc[author.current ? 0 : 1].push(author);
    return acc;
  }, [[], []] as ArticleAuthorsSectionProps["authors"][]);
  const title = t["article"]["authors-title"];
  return <div data-sentry-component="AuthorsSection" data-sentry-source-file="AuthorsSection.tsx">
            <h2 id={id}>{title} </h2>
            <ul>
                {currentAuthors.map(author => <Author key={author.name} {...author} />)}
            </ul>

            {formerAuthors.length ? <>
                    <h4>{t["article"]["former-authors"]}</h4>
                    <ul>
                        {formerAuthors.map(author => author.name ? <Author key={author.name} {...author} /> : null)}
                    </ul>
                </> : null}
        </div>;
}
function Author(props: IAuthor) {
  const {
    name,
    title,
    organization
  } = props;
  return <li data-sentry-component="Author" data-sentry-source-file="AuthorsSection.tsx">
            {name} {title ? <span>, {title}</span> : null}
            {organization ? <span>, {organization}</span> : null}
        </li>;
}