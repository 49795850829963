import { PortableTextProps, PortableTextReactComponents, PortableTextTypeComponentProps, PortableText as SanityPortableText } from "@portabletext/react";
import { Image as ImageComponent } from "components/Image";
import UnknownMark from "components/PortableText/UnknownMark";
import UnknownType from "components/PortableText/UnknownType";
import { RealtimeGuidelinesType, useGetHDIRGuidelines } from "data/contentData/hooks/hdir.hooks";
import usePath from "hooks/usePath";
export default function PortableTextHTML(props: PortableTextProps) {
  if (!props.value) {
    return null;
  }
  return <SanityPortableText {...props} components={components} data-sentry-element="SanityPortableText" data-sentry-component="PortableTextHTML" data-sentry-source-file="PortableTextHTML.tsx" />;
}
const components: Partial<PortableTextReactComponents> = {
  marks: {
    subscript: ({
      children
    }) => <sub>{children}</sub>,
    superscript: ({
      children
    }) => <sup>{children}</sup>
  },
  types: {
    "image-list": ImageList,
    image: Image,
    note: Note,
    "realtime-guideline": RealtimeGuideline,
    ref: ({
      value
    }) => <sup>{value.ids.join(",")}</sup>,
    table: Table
  },
  hardBreak: () => <br />,
  list: ({
    children
  }) => <ul>{children}</ul>,
  listItem: ({
    children
  }) => <li>{children}</li>,
  unknownMark: UnknownMark,
  unknownType: UnknownType
};
interface IImageProps {
  src: string;
  alt: string;
  title?: string;
  caption?: string;
}
function Image({
  value
}: PortableTextTypeComponentProps<IImageProps>) {
  const {
    src,
    alt,
    title,
    caption
  } = value;
  return <figure data-sentry-component="Image" data-sentry-source-file="PortableTextHTML.tsx">
            {title && <p>{title}</p>}

            <ImageComponent nativeImg src={src} alt={alt} data-sentry-element="ImageComponent" data-sentry-source-file="PortableTextHTML.tsx" />
            {caption && <figcaption>{caption}</figcaption>}
        </figure>;
}
function ImageList({
  value
}: PortableTextTypeComponentProps<{
  images: IImageProps[];
}>) {
  return <>
            {value.images.map(image => <figure key={image.src}>
                    {image.title && <p>{image.title}</p>}

                    <ImageComponent nativeImg src={image.src} alt={image.alt} />
                    {image.caption && <figcaption>{image.caption}</figcaption>}
                </figure>)}
        </>;
}
function Note({
  value
}: PortableTextTypeComponentProps<{
  title: string;
  blockContent?: string;
}>) {
  const {
    title,
    blockContent
  } = value;
  return <section data-sentry-component="Note" data-sentry-source-file="PortableTextHTML.tsx">
            <SanityPortableText value={JSON.parse(title)} data-sentry-element="SanityPortableText" data-sentry-source-file="PortableTextHTML.tsx" />
            {blockContent && <SanityPortableText value={JSON.parse(blockContent)} />}
        </section>;
}
function RealtimeGuideline({
  value
}: PortableTextTypeComponentProps<RealtimeGuidelinesType>) {
  const asPath = usePath(true);
  const {
    data
  } = useGetHDIRGuidelines(value.id, asPath, !!asPath);
  if (!data || !data.blockContent || !value.title) {
    return null;
  }
  return <section data-sentry-component="RealtimeGuideline" data-sentry-source-file="PortableTextHTML.tsx">
            <h4>{value.title}</h4>
            <SanityPortableText value={JSON.parse(data?.blockContent)} data-sentry-element="SanityPortableText" data-sentry-source-file="PortableTextHTML.tsx" />
            {data.source && <h5>{data.source}</h5>}
        </section>;
}
interface ITableProps {
  caption?: string;
  rows: Array<Array<{
    blockContent: string;
    colSpan?: number;
    rowSpan?: number;
  }>>;
}
function Table({
  value
}: PortableTextTypeComponentProps<ITableProps>) {
  return <table className="html-table" data-sentry-component="Table" data-sentry-source-file="PortableTextHTML.tsx">
            {value.caption && <caption>
                    <SanityPortableText value={JSON.parse(value.caption)} />
                </caption>}
            <tbody>
                {value.rows.map((row, rowIndex) =>
      // eslint-disable-next-line react/no-array-index-key
      <tr key={rowIndex}>
                        {row.map(({
          blockContent,
          ...props
        }, cellIndex) => <td
        // eslint-disable-next-line react/no-array-index-key
        key={cellIndex} {...props}>
                                <SanityPortableText value={JSON.parse(blockContent)} />
                            </td>)}
                    </tr>)}
            </tbody>
        </table>;
}