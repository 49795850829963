import { Image } from "components/Image";
import PortableTextHTML from "components/PortableText/PortableTextHTML";
import { AuthorsSection } from "components/article/plain-html/AuthorsSection";
import { Header } from "components/article/plain-html/Header";
import { ReferenceSection } from "components/article/plain-html/ReferenceSection";
import { styled } from "@mui/material/styles";
import { IPage } from "data/contentData/api/content";
import getContentIds from "lib/get-sources-ids";
export default function HTMLPageTemplate({
  page
}: {
  page: IPage;
}) {
  return <StyledWrapper data-sentry-element="StyledWrapper" data-sentry-component="HTMLPageTemplate" data-sentry-source-file="HTMLPageTemplate.tsx">
            <article id="htmlArticle">
                {page.image ? <figure>
                        <Image nativeImg src={page.image.src} alt={page.image.alt ?? ""} />
                        <figcaption>{page.image.caption}</figcaption>
                    </figure> : null}

                <Header page={page} data-sentry-element="Header" data-sentry-source-file="HTMLPageTemplate.tsx" />
                {page.sections?.map(section => {
        switch (section.type) {
          case "facts":
            return <section key={section.url + section.title}>
                                    {section.name && <h2>{section.name}</h2>}
                                    <PortableTextHTML key={section.url} value={section.blockContent} />
                                </section>;
          default:
            return <section key={section.url + section.title}>
                                    {section.name && <h2>{section.name}</h2>}
                                    <PortableTextHTML key={section.url} value={section.blockContent} />

                                    {section.sections?.map(subSection => <section key={subSection.url}>
                                            <h3>{subSection.name}</h3>
                                            <PortableTextHTML key={subSection.url} value={subSection.blockContent} />
                                        </section>)}
                                </section>;
        }
      })}

                <Sources page={page} data-sentry-element="Sources" data-sentry-source-file="HTMLPageTemplate.tsx" />
            </article>
        </StyledWrapper>;
}
const StyledWrapper = styled("div")(({
  theme
}) => ({
  backgroundColor: "white",
  margin: theme.spacing(2),
  padding: theme.spacing(2)
}));
interface ISourcesProps {
  page: IPage;
}
function Sources({
  page
}: ISourcesProps) {
  const {
    authorsContentId,
    referencesContentId
  } = getContentIds(page);

  // If page.sources does NOT exist => references & authors does NOT exist as well!
  if (!page.sources) {
    return null;
  }
  const {
    blockContent,
    url,
    name,
    sections
  } = page.sources;
  return <section data-sentry-component="Sources" data-sentry-source-file="HTMLPageTemplate.tsx">
            <h2 id={url}>{name}</h2>
            {blockContent ? <PortableTextHTML value={blockContent} /> : null}
            {sections?.map(subSection => {
      const blockContentSubSection = subSection.blockContent ? <PortableTextHTML key={subSection.url} value={subSection.blockContent} /> : null;
      if (subSection.name) {
        return <div key={subSection.name}>
                            <h3 id={subSection.url}>{subSection.name}</h3>
                            {blockContentSubSection}
                        </div>;
      }
      return blockContentSubSection;
    })}
            {page.references && <ReferenceSection references={page.references} id={referencesContentId} />}
            {page.authors && <AuthorsSection authors={page.authors} id={authorsContentId} />}
        </section>;
}