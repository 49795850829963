import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
interface IDictionaryPopoverPortalProps {
  children: any;
  className?: string;
  element?: string;
  open: boolean;
}
export function DictionaryPopoverPortal({
  children,
  className = "dictionary-portal",
  element = "div",
  open
}: IDictionaryPopoverPortalProps) {
  const [container] = useState(() => {
    const el = document.createElement(element);
    el.classList.add(className);
    return el;
  });
  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);
  if (!open) return null;
  return createPortal(children, container);
}