import Link from "components/Link";
import List from "@mui/material/List";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { CardColorType } from "data/contentData/api/content";
import { useGetLatestArticles } from "data/contentData/hooks/search.hooks";
import { toLocalDate } from "lib/string/date-to-locale-date-string";
interface LatestArticlesContentProps {
  includeNew: boolean;
  includeRevised: boolean;
  includeUpdated: boolean;
  limit: number;
  templateGroup: string;
  displayDate: boolean;
  cardColor: CardColorType;
}
export function LatestArticlesList(props: LatestArticlesContentProps) {
  const {
    isLoading,
    data
  } = useGetLatestArticles(props.templateGroup, props.limit, props.includeNew, props.includeUpdated, props.includeRevised);
  if (isLoading) {
    return <List>
                {[...Array(props.limit).keys()].map(id => <LatestArticlesListItem key={id} href="/" caption={<Skeleton height={19} width={68} />} color={props.cardColor}>
                        <Skeleton width={150} />
                    </LatestArticlesListItem>)}
            </List>;
  }
  if (!data) {
    return null;
  }
  const dataWithId = data.map((article, index) => ({
    ...article,
    id: index
  }));
  return <List data-sentry-element="List" data-sentry-component="LatestArticlesList" data-sentry-source-file="LatestArticlesContent.tsx">
            {dataWithId.map(({
      url,
      title,
      timestamp,
      id
    }) => <LatestArticlesListItem key={id} caption={props.displayDate ? toLocalDate(timestamp, process.env.NEXT_PUBLIC_LOCALE) : null} href={url} color={props.cardColor}>
                    {title}
                </LatestArticlesListItem>)}
        </List>;
}
interface LatestArticlesListItemProps {
  children: React.ReactNode;
  href: string;
  caption?: React.ReactNode;
  color: CardColorType;
}
function LatestArticlesListItem(props: LatestArticlesListItemProps) {
  const {
    caption,
    href,
    children,
    color
  } = props;
  const textColor = color === "primary" || color === "secondary" ? "common.white" : "link.light";
  return <StyledLi data-sentry-element="StyledLi" data-sentry-component="LatestArticlesListItem" data-sentry-source-file="LatestArticlesContent.tsx">
            {caption ? <>
                    <Typography component="p" variant="caption">
                        {caption}
                    </Typography>
                    <StyledLink href={href} variant="body2" noWrap color={textColor}>
                        {children}
                    </StyledLink>
                </> : <StyledLink href={href} variant="body2" noWrap color={textColor}>
                    {children}
                </StyledLink>}
        </StyledLi>;
}
const StyledLi = styled("li")({
  padding: "8px 0",
  listStyleType: "none",
  marginLeft: 0
});
const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline"
  },
  maxWidth: "100%",
  display: "inline-block"
}));