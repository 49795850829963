import { PortableTextBlock } from "@portabletext/types";
import Accordion from "components/UI/Accordion";
import PortableText from "components/PortableText/PortableText";
import useHashChange from "hooks/useHashChange";
import { t } from "locales";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import { ISection } from "data/contentData/api/content";
import { useGetUserSettings } from "data/contentData/hooks/user.hooks";
import { useTheme } from "@mui/material/styles";
interface IProps {
  section: ISection;
}
export default function RegionalContent({
  section
}: IProps) {
  const theme = useTheme();
  const {
    data: userSettings,
    isLoading
  } = useGetUserSettings();
  const userRegion = userSettings?.region ?? "none";
  const [expanded, setExpanded] = useState<string>("none");
  const handleExpand = (panel: string) => () => {
    setExpanded(expanded === panel ? "none" : panel);
  };
  const [sectionExpanded, setSectionExpanded] = useState(false);
  useHashChange(href => href.startsWith(section.url) && setSectionExpanded(true));
  return <Accordion id={section.url} level={"h2"} summaryBackground={theme.palette.bhc[800]} summaryColor={theme.palette.text.onDark} borderRadius={"6px"} header={section.heading ? <PortableText value={section.heading} /> : section.name} collapsed={userRegion === "none" && !sectionExpanded} data-sentry-element="Accordion" data-sentry-component="RegionalContent" data-sentry-source-file="RegionalContent.tsx">
            {section.sections?.map((region, i) => {
      // If region.regions is undefiend/null, it means this regional content should be seen by any region
      // We give it a custom "region-{index}" to be able to toggle its expanded state
      const regionName = region.hasReadAccess?.[0] ?? `region-${i}`;
      return <Region key={region.url} expanded={userRegion === regionName || regionName === expanded} loading={isLoading} onExpand={handleExpand} userRegion={userRegion} regionName={regionName} {...region} />;
    }) ?? null}
        </Accordion>;
}
interface RegionProps {
  expanded: boolean;
  regionName: string;
  url: string;
  name: string;
  heading: PortableTextBlock[];
  loading: boolean;
  blockContent: PortableTextBlock[];
  onExpand: (panel: string) => () => void;
  userRegion: string;
}
function Region(props: RegionProps) {
  const {
    name,
    heading,
    url,
    userRegion,
    regionName,
    loading,
    expanded,
    blockContent
  } = props;
  const collapsed = loading || !expanded;
  const theme = useTheme();
  return <Accordion key={url} collapsed={collapsed} id={url} level={"h3"} summaryBackground={theme.palette.grey[100]} summaryHoverBackground={theme.palette.grey[300]} header={heading ? <PortableText value={heading} /> : <>
                        {name}
                        {userRegion === regionName ? <Typography component="span" textAlign="right" flex={1} mr={1}>
                                {t["regional-content"]["default"]}
                            </Typography> : null}
                    </>} data-sentry-element="Accordion" data-sentry-component="Region" data-sentry-source-file="RegionalContent.tsx">
            {regionName.startsWith("region-") ? null : <Typography align="right" p={0.5} color={"action.disabled"} component="span" variant="h6">
                    {regionName}
                </Typography>}
            <PortableText value={blockContent} data-sentry-element="PortableText" data-sentry-source-file="RegionalContent.tsx" />
        </Accordion>;
}