import Link from "components/Link";
import { styled } from "@mui/material/styles";
interface IProps {
  name: string;
  href: string;
  isOutdated: boolean;
}
export function ListPageLink({
  name,
  href,
  isOutdated
}: IProps) {
  return <StyledLink noWrap href={href} title={name} isoutdated={isOutdated.toString()} data-sentry-element="StyledLink" data-sentry-component="ListPageLink" data-sentry-source-file="ListPageLink.tsx">
            {name}
        </StyledLink>;
}
const StyledLink = styled(Link)<{
  isoutdated: string;
}>(({
  isoutdated,
  theme
}) => ({
  maxWidth: "100%",
  display: "inline-block",
  ...(isoutdated === "true" && {
    fontWeight: "bold",
    color: theme.palette.error.dark
  }),
  [theme.breakpoints.up("md")]: {
    maxWidth: "80%"
  }
}));