import Link from "components/Link";
import List from "@mui/material/List";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { CardColorType } from "data/contentData/api/content";
import { useGetArticleUpdates } from "data/contentData/hooks/content.hooks";
import { toLocalDate } from "lib/string/date-to-locale-date-string";
interface ImportantUpdatesContentProps {
  skip: number;
  take: number;
  onlyImportant: boolean;
  displayDate: boolean;
  cardColor: CardColorType;
}
export function ImportantUpdatesList(props: ImportantUpdatesContentProps) {
  const {
    isLoading,
    data
  } = useGetArticleUpdates(props.skip, props.take, props.onlyImportant, props.displayDate);
  if (isLoading) {
    return <List disablePadding>
                {[...Array(props.take).keys()].map(id => <ImportantUpdatesListItem key={id} href="/" caption={<Skeleton height={19} width={68} />} title={<Skeleton width={150} />} color={props.cardColor} />)}
            </List>;
  }
  return <StyledUL data-sentry-element="StyledUL" data-sentry-component="ImportantUpdatesList" data-sentry-source-file="ImportantUpdatesContent.tsx">
            {data?.map(({
      contentId,
      published,
      owner
    }) => <ImportantUpdatesListItem key={contentId} caption={toLocalDate(published, process.env.NEXT_PUBLIC_LOCALE)} href={owner.url} title={owner.name} color={props.cardColor} />)}
        </StyledUL>;
}
interface ImportantUpdatesListItemProps {
  href?: string;
  caption?: React.ReactNode;
  title?: React.ReactNode;
  color: CardColorType;
}
function ImportantUpdatesListItem(props: ImportantUpdatesListItemProps) {
  const {
    caption,
    title,
    href,
    color
  } = props;
  const textColor = color === "primary" || color === "secondary" ? "common.white" : "link.light";
  return <StyledLI data-sentry-element="StyledLI" data-sentry-component="ImportantUpdatesListItem" data-sentry-source-file="ImportantUpdatesContent.tsx">
            {caption ? <>
                    <Typography component="p" variant="caption">
                        {caption}
                    </Typography>
                    <StyledLink href={href} variant="body2" noWrap color={textColor}>
                        {title}
                    </StyledLink>
                </> : <StyledLink href={href} variant="body2" noWrap color={textColor}>
                    {title}
                </StyledLink>}
        </StyledLI>;
}
const StyledUL = styled("ul")(({
  theme
}) => ({
  padding: theme.spacing(2, 0),
  listStyleType: "none",
  marginLeft: 0
}));
const StyledLI = styled("li")(({
  theme
}) => ({
  padding: theme.spacing(0.5, 0)
}));
const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline"
  },
  maxWidth: "100%",
  display: "inline-block"
}));