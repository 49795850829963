/* eslint-disable i18next/no-literal-string */
import InteractiveJSON from "components/Debug/InteractiveJson";
import { Icon } from "components/Icon";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
export function Pre({
  json,
  expandTopLevel = false
}: {
  json: string;
  expandTopLevel?: boolean;
}) {
  const [showInteractive, setShowInteractive] = useState(true);
  const toggleShowInteractive = () => setShowInteractive(p => !p);
  return <StyledDiv data-sentry-element="StyledDiv" data-sentry-component="Pre" data-sentry-source-file="Pre.tsx">
            <JSONButtonsWrapper data-sentry-element="JSONButtonsWrapper" data-sentry-source-file="Pre.tsx">
                <CopyButton data={json} data-sentry-element="CopyButton" data-sentry-source-file="Pre.tsx" />
                <StyledIconButton showinteractive={showInteractive.toString()} onClick={toggleShowInteractive} data-sentry-element="StyledIconButton" data-sentry-source-file="Pre.tsx">
                    <Icon name="code" data-sentry-element="Icon" data-sentry-source-file="Pre.tsx" />
                </StyledIconButton>
            </JSONButtonsWrapper>
            {showInteractive ? <StyledPre>
                    <InteractiveJSON json={json} expandTopLevel={expandTopLevel} />
                </StyledPre> : <StyledPre>{json}</StyledPre>}
        </StyledDiv>;
}
const StyledDiv = styled("div")({
  position: "relative"
});
const JSONButtonsWrapper = styled("div")(({
  theme
}) => ({
  position: "absolute",
  display: "flex",
  gap: theme.spacing(1),
  top: theme.spacing(2),
  right: theme.spacing(4)
}));
const StyledIconButton = styled(IconButton)<{
  showinteractive: string;
}>(({
  showinteractive
}) => ({
  backgroundColor: showinteractive ? grey[300] : "initial"
}));
const StyledPre = styled("pre")(({
  theme
}) => ({
  whiteSpace: "pre-wrap",
  wordBreak: "break-all",
  margin: 0,
  padding: theme.spacing(2),
  fontSize: "0.8rem",
  height: "50vh",
  overflow: "auto",
  backgroundColor: grey[100]
}));
function CopyButton({
  data
}: {
  data: string;
}) {
  const [finishedCopy, setFinishedCopy] = useState(false);
  const copyPageData = () => {
    setFinishedCopy(false);
    navigator.clipboard.writeText(data).then(() => {
      setFinishedCopy(true);
      setTimeout(() => {
        setFinishedCopy(false);
      }, 3000);
    }).catch(_ => {
      setFinishedCopy(true);
    });
  };
  return <IconButton onClick={copyPageData} data-sentry-element="IconButton" data-sentry-component="CopyButton" data-sentry-source-file="Pre.tsx">
            {finishedCopy ? <Icon name="check" /> : <Icon name="contentCopy" />}
        </IconButton>;
}