import { t } from "locales";
import { IPage } from "data/contentData/api/content";
import { toLocalDate } from "lib/string/date-to-locale-date-string";
interface IProps {
  page: IPage;
}
export function Header({
  page
}: IProps) {
  const {
    title,
    updated,
    revised,
    published,
    revisionInterval,
    authors,
    ingress,
    byline
  } = page;
  return <div data-sentry-component="Header" data-sentry-source-file="Header.tsx">
            {title && <h1>{title}</h1>}

            {ingress && <p>{ingress}</p>}

            {byline && <p>{byline}</p>}

            {published && <p>
                    {t["published"]} :{" "}
                    {toLocalDate(published, process.env.NEXT_PUBLIC_LOCALE)}
                </p>}

            {updated && <p>
                    {t["last-updated"]}:{" "}
                    {toLocalDate(updated, process.env.NEXT_PUBLIC_LOCALE)}
                </p>}

            {revised && <p>
                    {t["last-revised"]}:{" "}
                    {toLocalDate(revised, process.env.NEXT_PUBLIC_LOCALE)}
                </p>}

            {revisionInterval && <p>
                    {t["revision-interval"]}: {revisionInterval}
                </p>}

            {authors && <p>
                    {t["last-approved-by"]} :{" "}
                    {authors?.map(author => <span key={author.name}>{author.name}</span>)}
                </p>}
        </div>;
}