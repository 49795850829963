import { Drawer } from "components/Drawer/Drawer";
import { Icon, IconType } from "components/Icon";
import Link from "components/Link";
import { t } from "locales";
import { ReactNode } from "react";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { ILinkList } from "data/contentData/api/content";
interface IProps {
  lists: ILinkList[] | undefined;
  open: boolean;
  onClose: () => void;
  regionalContentId?: string;
}
export function DrawerTools({
  lists,
  onClose,
  open,
  regionalContentId
}: IProps) {
  return <Drawer header={<MenuItem component="div" disabled>
                    <Typography align="center" color="inherit" variant="subtitle2">
                        {t.tools.title}
                    </Typography>
                </MenuItem>} position="right" open={open} onClose={onClose} data-sentry-element="Drawer" data-sentry-component="DrawerTools" data-sentry-source-file="DrawerTools.tsx">
            {regionalContentId ? <DrawerContent icon="regional" title={t["tools"]["regional-content"]["title"]}>
                    <Typography variant="body2">
                        {t["tools"]["regional-content"]["content"]}
                    </Typography>
                    <Link href={`#${regionalContentId}`} variant="body2">
                        {t["tools"]["regional-content"]["action"]}
                    </Link>
                </DrawerContent> : null}
            {lists?.map((list, index) => <DrawerTool expanded={index < 2} key={list.name} onClose={onClose} list={list} />)}
        </Drawer>;
}
type DrawerContentProps = {
  icon?: IconType;
  title: string;
  children: ReactNode;
};
function DrawerContent(props: DrawerContentProps) {
  const {
    icon,
    title,
    children
  } = props;
  return <Grid container mb={2} data-sentry-element="Grid" data-sentry-component="DrawerContent" data-sentry-source-file="DrawerTools.tsx">
            <Grid item display="flex" p={2} data-sentry-element="Grid" data-sentry-source-file="DrawerTools.tsx">
                {icon ? <Icon name={icon} /> : null}
                <Typography variant="body1" fontWeight={700} pl={1} data-sentry-element="Typography" data-sentry-source-file="DrawerTools.tsx">
                    {title}
                </Typography>
            </Grid>
            <Grid item py={1} px={1} data-sentry-element="Grid" data-sentry-source-file="DrawerTools.tsx">
                {children}
            </Grid>
        </Grid>;
}
interface DrawerToolProps {
  expanded?: boolean;
  onClose(): void;
  list: ILinkList;
}
function DrawerTool(props: DrawerToolProps) {
  const {
    expanded = false,
    onClose,
    list
  } = props;
  const [open, setOpen] = useState(expanded);
  const {
    icon,
    name,
    links
  } = list;
  const handleClick = () => {
    setOpen(!open);
  };
  return <StyledAccordion expanded={open} onChange={handleClick} data-sentry-element="StyledAccordion" data-sentry-component="DrawerTool" data-sentry-source-file="DrawerTools.tsx">
            <Summary expandIcon={<Icon name="expandMore" />} aria-controls="panel1bh-content" id="panel1bh-header" data-sentry-element="Summary" data-sentry-source-file="DrawerTools.tsx">
                {icon ? <Icon name={icon} /> : null}
                <Typography variant="body1" component="span" fontWeight={700} pl={1} data-sentry-element="Typography" data-sentry-source-file="DrawerTools.tsx">
                    {name}
                </Typography>
            </Summary>
            <StyledAccordionDetails data-sentry-element="StyledAccordionDetails" data-sentry-source-file="DrawerTools.tsx">
                <List data-sentry-element="List" data-sentry-source-file="DrawerTools.tsx">
                    {links.map(link => <ListItem key={link.text}>
                            <Link width={"100%"} noWrap href={link.href} title={link.text} onClick={onClose}>
                                {link.text}
                            </Link>
                        </ListItem>)}
                </List>
            </StyledAccordionDetails>
        </StyledAccordion>;
}
const StyledAccordion = styled(Accordion)(({
  theme
}) => ({
  width: "100%",
  boxShadow: "none",
  marginBottom: theme.spacing(2),
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  },
  "&.Mui-expanded": {
    margin: "auto"
  }
}));
const Summary = styled(AccordionSummary)(({
  theme
}) => ({
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(0.5, 2),
  "&.Mui-expanded": {
    minHeight: 48
  }
}));
const StyledAccordionDetails = styled(AccordionDetails)(({
  theme
}) => ({
  padding: theme.spacing(1, 0.5)
}));