import { useCallback, useLayoutEffect, useState } from "react"

type TextSelectionState = {
    clientRect: ClientRect
    textContent: string
}

/**
 * useTextSelection()
 *
 * @description
 * hook to get information about the current text selection
 *
 */
export function useTextSelection() {
    const [state, setState] = useState<TextSelectionState | null>(null)
    const reset = () =>
        setState(prevState => {
            if (!prevState) return prevState // -> NO RERENDER !
            return null
        })

    const handler = useCallback(() => {
        const selection = window.getSelection()

        if (selection == null || !selection.rangeCount) {
            reset()
            return
        }

        const range = selection.getRangeAt(0)

        if (range == null) {
            reset()
            return
        }

        const contents = range.cloneContents()

        if (contents.textContent != null) {
            if (isWholeWordSelected(selection)) {
                const textContents = contents.textContent.trim()
                const clientRects = getClientRects(range)
                if (clientRects) {
                    setState(prevState => {
                        if (
                            prevState?.textContent.trim() === textContents &&
                            prevState?.clientRect.x === clientRects?.x &&
                            prevState?.clientRect.y === clientRects?.y
                        )
                            return prevState // -> NO RERENDER !
                        return {
                            textContent: textContents,
                            clientRect: clientRects
                        }
                    })
                } else {
                    reset()
                }
            } else {
                reset()
            }
        }
    }, [])

    useLayoutEffect(() => {
        document.addEventListener("selectionchange", handler)
        document.addEventListener("keydown", handler)
        document.addEventListener("keyup", handler)
        window.addEventListener("resize", handler)

        return () => {
            document.removeEventListener("selectionchange", handler)
            document.removeEventListener("keydown", handler)
            document.removeEventListener("keyup", handler)
            window.removeEventListener("resize", handler)
        }
    }, [handler])

    return state
}

type ClientRect = DOMRect

function roundValues(_rect: ClientRect) {
    const rect = {
        ..._rect
    }
    for (const key of Object.keys(rect)) {
        // @ts-ignore
        rect[key] = Math.round(rect[key])
    }
    return rect
}

function getClientRects(range: Range) {
    const rects = range.getClientRects()
    let newRect: ClientRect

    if (rects.length === 0 && range.commonAncestorContainer != null) {
        const el = range.commonAncestorContainer as HTMLElement
        newRect = roundValues(el.getBoundingClientRect().toJSON())
    } else {
        if (rects.length < 1) return

        newRect = roundValues(rects[0].toJSON())
    }

    return newRect
}

function isWholeWordSelected(selection: Selection | null) {
    if (!selection || selection.rangeCount === 0) return false

    const range = selection.getRangeAt(0)
    const selectedText = selection.toString().trim()

    if (!selectedText) return false // Nothing selected

    const anchorNode = range.startContainer
    const fullText = anchorNode.textContent

    if (!fullText) return false

    // Find all whole words in the full text
    const words = fullText.match(/\b\w+\b/g)

    if (!words) return false

    // Split selected text into words
    const selectedWords = selectedText.match(/\b\w+\b/g)

    if (!selectedWords) return false // Selection is not valid words

    // Ensure every selected word exists as a whole word in the original text
    return selectedWords.every(word => words.includes(word))
}
