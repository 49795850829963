import { refreshPage } from "data/internal/api/refresh-page"
import useMutateData from "hooks/useMutateData"

export function useRefreshPage(onSuccessful?: () => void, onError?: () => void) {
    return useMutateData(
        async (path: { path: string }) => await refreshPage(path),
        onSuccessful,
        onError
    )
}
