import { t } from "locales";
import React from "react";
import { IReference } from "data/contentData/api/content";
interface IProps {
  id: string;
  references: IReference[];
}
export function ReferenceSection({
  id,
  references
}: IProps) {
  if (!references) {
    return null;
  }
  return <section data-sentry-component="ReferenceSection" data-sentry-source-file="ReferenceSection.tsx">
            <h3 id={id}>{t.article["references-title"]}</h3>
            <ol>
                {references.map(({
        referenceId,
        text,
        link
      }) => <li key={referenceId + text}>
                        {text}
                        {link && <a href={link.href}>
                                {link.text ?? new URL(link.href).host}
                            </a>}
                    </li>)}
            </ol>
        </section>;
}