"use client";

import { IPage } from "data/contentData/api/content";
import { useGTMCustomVariables } from "hooks/useGTMCustomVariables";
export default function GTMCustomVariables({
  page
}: {
  page?: IPage;
}) {
  useGTMCustomVariables(page);
  return null;
}