"use client";

import HTMLPageTemplate from "components/article/templates/HTMLPageTemplate";
import { JSX, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { IPage } from "data/contentData/api/content";
import CopyHtmlButton from "./CopyHtmlButton";
export default function Preview({
  page,
  pageComponent
}: {
  page: IPage;
  pageComponent: JSX.Element | undefined;
}) {
  const [isHtmlView, setIsHtmlView] = useState(false);
  const handlePreviewToggle = () => {
    setIsHtmlView(!isHtmlView);
  };
  return <>
            <StyledTypography variant="h1" data-sentry-element="StyledTypography" data-sentry-source-file="Preview.tsx">{"PREVIEW"}</StyledTypography>
            <StyledButtonWrapper data-sentry-element="StyledButtonWrapper" data-sentry-source-file="Preview.tsx">
                {isHtmlView && <CopyHtmlButton />}
                <FormControlLabel control={<Switch checked={isHtmlView} onChange={handlePreviewToggle} color="secondary" />} label={"HTML"} data-sentry-element="FormControlLabel" data-sentry-source-file="Preview.tsx" />
            </StyledButtonWrapper>
            {isHtmlView ? <HTMLPageTemplate page={page} /> : pageComponent}
        </>;
}
const StyledTypography = styled(Typography)(({
  theme
}) => ({
  "@keyframes pulse": {
    "0%": {
      opacity: 1
    },
    "20%": {
      opacity: 1
    },
    "100%": {
      opacity: 0.1
    }
  },
  color: theme.palette.error.contrastText,
  backgroundColor: theme.palette.error.dark,
  letterSpacing: theme.spacing(1),
  padding: theme.spacing(2),
  textAlign: "center",
  animation: "pulse 3s 0s infinite alternate"
}));
const StyledButtonWrapper = styled("div")(({
  theme
}) => {
  const {
    contentMax,
    leftAside,
    rightAside
  } = theme.constants.width;
  const maxWidth = contentMax + leftAside + rightAside;
  return {
    width: "100%",
    maxWidth: `min(100vw, ${maxWidth}px)`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    gap: theme.spacing(1),
    padding: `${theme.spacing(1)}`,
    margin: "auto"
  };
});