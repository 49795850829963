import { useRef } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { DictionaryPopoverPortal } from "./DictionaryPopoverPortal";
import { useDictionarySelectionSearch } from "./useDictionarySelectionSearch";
export function DictionaryPopoverWithLookup() {
  const {
    rect,
    definition
  } = useDictionarySelectionSearch();
  const myRef = useRef<HTMLDivElement | null>(null);
  const open = Boolean(definition?.description) && Boolean(definition?.words);
  if (!rect || !definition) return null;
  return <DictionaryPopoverPortal open={open} data-sentry-element="DictionaryPopoverPortal" data-sentry-component="DictionaryPopoverWithLookup" data-sentry-source-file="DictionaryPopoverWithLookup.tsx">
            <StyledPaper elevation={3} ref={myRef} left={`${rect.x}px`} top={`${rect.y + window.pageYOffset + rect.height}px`} data-sentry-element="StyledPaper" data-sentry-source-file="DictionaryPopoverWithLookup.tsx">
                <Typography variant="overline" data-sentry-element="Typography" data-sentry-source-file="DictionaryPopoverWithLookup.tsx">
                    {definition?.words.join(", ")}
                </Typography>
                <Typography variant="body2" data-sentry-element="Typography" data-sentry-source-file="DictionaryPopoverWithLookup.tsx">
                    {definition?.description}
                </Typography>
            </StyledPaper>
        </DictionaryPopoverPortal>;
}
const StyledPaper = styled(Paper)<{
  left: string;
  top: string;
}>(({
  left,
  theme,
  top
}) => ({
  backgroundColor: "white",
  padding: theme.spacing(1, 2, 2, 2),
  position: "absolute",
  left,
  top,
  maxWidth: "580px",
  zIndex: theme.zIndex.fab
}));